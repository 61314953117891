.navbar-main {
    border-bottom: 1px solid #f5f5f5;
}

.navbar-main-color {
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.navbar-box {
    display: flex;
    justify-content: space-between;
    padding: 1.25em;
    max-width: 100%;
    background-color: transparent;
    font-size: 1.1em;
    font-weight: 700;
    font-family: 'Baloo Paaji 2', cursive;
    text-decoration: none;
}

a:active, a:visited, a:hover{
    color: inherit;
    text-decoration: none;
}

@media(max-width : 576px) {
    .navbar-box {
        font-size: 0.85em;
    }
}

.navbar-box-color {
    display: flex;
    justify-content: space-between;
    padding: 1.25em;
    max-width: 100%;
    font-size: 1.1em;
    font-weight: 700;
    font-family: 'Baloo Paaji 2', cursive;
    color: white;
}

@media(max-width : 576px) {
    .navbar-box-color {
        font-size: 0.85em;
    }
}

.input-container {
    display: flex;
    width: 100%;
    border: 3px solid transparent;
    border-radius: 5px;
}

.input-container:hover{
    border: 3px solid;
    border-radius: 5px;
}

.input-container:focus-within{
    border: 3px solid;
    border-radius: 5px;
}

.input-container-color {
    display: flex;
    width: 100%;
    border: 3px solid transparent;
    border-radius: 5px;
}

.input-container-color:hover{
    border: 3px solid white;
    border-radius: 5px;
}

.input-container-color:focus-within{
    border: 3px solid white;
    border-radius: 5px;
}

.icon {
    padding: 10px 5px;
    color: black;
    text-align: center;
}

.icon-color {
    padding: 10px 5px;
    color: white;
    text-align: center;
}
  
.input-field {
    border: 0px;
    font-weight: 700;
    font-family: 'Baloo Paaji 2', cursive;
    color: black;
    opacity: 1; /* Firefox */
    background: transparent;
}

.input-field-color {
    border: 0px;
    font-weight: 700;
    font-family: 'Baloo Paaji 2', cursive;
    color: white;
    opacity: 1; /* Firefox */
    background: transparent;
}

.input-field:focus{
    outline: none;
    border: 0px;
}

.input-field-color:focus{
    outline: none;
    border: 0px;
}

.input-field::placeholder {
    font-weight: 700;
    font-family: 'Baloo Paaji 2', cursive;
    color: black;
    opacity: 1; /* Firefox */
}

.input-field-color::placeholder {
    font-weight: 700;
    font-family: 'Baloo Paaji 2', cursive;
    color: white;
    opacity: 1; /* Firefox */
}
  


